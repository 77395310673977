export const GAME_ICONS = {
  MINT: "/imgs/mint.png",
  EARN: "/imgs/earn.png",
  FRIENDS: "/imgs/friends.png",
  ME: "/imgs/me.png",
  ICON: "/imgs/icon.png",
  G_ICON: "/imgs/g_icon.png",
} as const;

export const BACKGROUNDS = {
  EARN: "/imgs/earnBg.png",
  MINT: "/imgs/mint/bg.png",
  FRIEND: "/imgs/friend/bg.png",
} as const;

export const TASK_ICONS = {
  TASK1: "/imgs/task_1.png",
  TASK2: "/imgs/task_2.png",
  TASK3: "/imgs/task_3.png",
  TASK4: "/imgs/task_4.png",
  TASK5: "/imgs/task_5.png",
} as const;

export const ME_ICONS = {
  AVATAR: "/imgs/me/avator.png",
  LANG: "/imgs/me/lang.png",
  ACCOUNT: "/imgs/me/account.png",
  WALLET: "/imgs/me/wallet_icon.png",
  ORDERS: "/imgs/me/my_orders.png",
  ARROW_RIGHT: "/imgs/me/arrow-right.png",
} as const;

export const MINT_ICONS = {
  BAG: "/imgs/mint/bag.png",
  TITLE_BG: "/imgs/mint/title_bg.png",
  MINT_ICON: "/imgs/mint/mint_icon.png",
  MINT_ICON_1: "/imgs/mint/mint_icon_1.png",
} as const;

export const FRIEND_ICONS = {
  MESSAGE_BG: "/imgs/friend/message_bg.png",
  ARROW_RIGHT: "/imgs/friend/arrow-right.png",
} as const;

export const HEADERS = {
  EARN_HEADER: "/imgs/earnHeader.png",
  EARN_TITLE: "/imgs/earn_title.png",
} as const;

export const NFT_IMAGES = {
  GOLD: "/nft/gold.png",
  HAMBURGER: "/nft/hambuger.png",
  GOLD_ORE: "/nft/gold_ore_1.png",
  IRON_ORE: "/nft/iron_ore_1.png",
  COPPER_ORE: "/nft/copper_ore_1.png",
  SILVER_ORE: "/nft/silver_ore_1.png",
  REFINING_POTION: "/nft/refining_potion_1.png",
  BOOST_CARD: "/nft/boost_card.png",
  OG_CARD: "/nft/og_card.png",
  EARLY_CARD: "/nft/early_card.png",
} as const;
